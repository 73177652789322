import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    codeLength: String
  }

  handlePaste(e) {
    e.preventDefault()
    let paste = (
      (e.clipboardData || window.clipboardData).getData('text') || ''
    )
      .trim()
      .slice(0, Number(this.codeLengthValue))

    let input
    for (let i = 0; i < paste.length; i++) {
      input = this.element.querySelector(`input#digit-${i + 1}`)
      input.value = paste[i]
    }
    input.focus()

    this.inputTarget.value = paste
    this.inputTarget.form.requestSubmit()
  }

  connect() {
    window.addEventListener('paste', this.handlePaste.bind(this))
  }

  disconnect() {
    window.removeEventListener('paste', this.handlePaste.bind(this))
  }

  checkAllowance(e) {
    if (!this._isValidOtpField(e.key)) {
      e.preventDefault()
    }
  }

  handleInputEvent(e) {
    const digitValue = e.data
    const manualInput = e.constructor.name === 'InputEvent'
    const validManualInput = digitValue !== null && manualInput && (('0' <= digitValue && digitValue <= '9') || ('a' <= digitValue && digitValue <= 'z'))
    const autoInput = e.constructor.name === 'CustomEvent'  // iOS auto-fill, data not included

    if (validManualInput || autoInput) {
      const next = this.element.querySelector(
        `input#${e.currentTarget.dataset.next}`
      )

      if (next !== null) {
        next.focus()
      }
    }

    this._updateMainField()
  }

  handleKeyUp(e) {
    if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
      const prev = this.element.querySelector(
        `input#${e.currentTarget.dataset.previous}`
      )

      if (prev !== null) {
        prev.focus()
      }
    } else if (e.key === 'ArrowRight') {
      const next = this.element.querySelector(
        `input#${e.currentTarget.dataset.next}`
      )

      if (next !== null) {
        next.focus()
      }
    }
  }

  _updateMainField() {
    let otpCode = ''
    for (var i = 1; i < Number(this.codeLengthValue) + 1; i += 1) {
      otpCode += this.element.querySelector(`input#digit-${i}`).value
    }

    if (otpCode.length === Number(this.codeLengthValue)) {
      this.inputTarget.value = otpCode
      this.inputTarget.form.requestSubmit()
    }
  }

  _isValidOtpField(key) {
    return (
      key === 'Backspace' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      ('0' <= key && key <= '9') ||
      ('a' <= key && key <= 'z')
    )
  }

  _allFieldsAreFilled() {
    return this.inputTarget.value.length === Number(this.codeLengthValue)
  }
}
